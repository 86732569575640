import React, { useState, createContext, useContext } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@pai-ui/core/theme/theme-provider";
// import { ThemeType } from '@pai-ui/core/theme/types';

const AppContext = createContext();
export const getAppContext = () => useContext(AppContext);

const App = React.lazy(() => import("./App.js"));

export const AppProvider = () => {
  const [contextData, updateContextData] = useState({});

  const setContextData = (data) => {
    updateContextData({ ...contextData, ...data });
  };

  return (
    <>
      <ThemeProvider>
        <React.Suspense fallback="Loading">
          <AppContext.Provider value={{ contextData, setContextData }}>
            <App />
          </AppContext.Provider>
        </React.Suspense>
      </ThemeProvider>
    </>
  );
};

ReactDOM.render(<AppProvider />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
